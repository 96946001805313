<template>
  <div id="newsStoryContent">
    <p id="Text1">
      Adventurers,
    </p>
    <p>
      We have some exciting news for you, direct from Nexus Tower!  We are pleased to announce that Dr. Overbuild and the other Faction Leaders have completed their final designs on Valiant Weapons!  The Faction Leaders discovered a way to harness the power of Imagination in its purest form, and it is incredibly powerful.  The Faction Leaders believe that you are now ready to handle the awesome power of Valiant Weapons – but you’ll need both hands to do it!
    </p>
    <center>
      <!-- pic39BC59547C08EC1DC3BF8B1DCCCA4BD7.jpg -->
      <img src="@/assets/news-network/valiant-weapons-1.jpg" class="rounded">
    </center>
    <p>
      The Faction Leaders designed 8 new Valiant Weapons, one for each Faction Specialty.  The Valiant Weapons were created to work with Rank 3 Gear and will also count towards your Rank 3 Multi-Item Bonuses!  Very soon, you will be able to purchase Valiant Weapons from your Faction Vendor in Nexus Tower for 175,000 coins and 500 Faction Tokens.
    </p>
    <center>
      <!-- pic1929DE3607C62C9D8375C3C0FD9F7D02.jpg -->
      <img src="@/assets/news-network/valiant-weapons-2.jpg" class="rounded">
    </center>
    <p>
      For such a price, what can you expect from Valiant Weapons?  A very unique feature of the Valiant Weapon is that a single swing will affect all nearby enemies.  Yes, all nearby enemies- that is the power of Pure Imagination!  Now you can easily take on wave after wave of enemies!  Each Valiant Weapon will also give you additional Armor and Imagination Points, and some of the Weapons even conjure crazy new creations to fight by your side!
    </p>
    <center>
      <!-- picBD24721DB0AAF64BDB6EEC423F904217.jpg -->
      <img src="@/assets/news-network/valiant-weapons-3.jpg" class="rounded">
    </center>
    <p>
      Hael Storm has created The Broadsider for Buccaneers and The Rutcarver for Daredevils!
    </p>
    <center>
      <!-- pic0C843EAEE01C0558D965125AE808FCDF.jpg -->
      <img style="width: 190px; height: 345px;" src="@/assets/news-network/valiant-venture-1.jpg" class="rounded">
      &nbsp;
      <!-- picFB25F985640F6AB574CB73AE38E9D2E3.jpg -->
      <img style="width: 190px; height: 345px;" src="@/assets/news-network/valiant-venture-2.jpg" class="rounded">
    </center>
    <p>
      Dr. Overbuild has created The Serratorizer for Engineers and The Wingreaper for Summoners!
    </p>
    <center>
      <!-- pic9D97BCFE4BEE2B7FE0E895109E1523D8.png -->
      <img style="width: 190px; height: 345px;" src="@/assets/news-network/valiant-assembly-1.png" class="rounded">
      &nbsp;
      <!-- picDFD1C232F47C1B89E5E32370320D8F2E.png -->
      <img style="width: 188px; height: 345px;" src="@/assets/news-network/valiant-assembly-2.png" class="rounded">
    </center>
    <p>
      Vanda Darkflame has created The Doomslicer for Sorcerers and The Wormholer for Space Mauraders!
    </p>
    <center>
      <!-- pic09434600CB4E9F1CBF949A6D49A1470D.jpg -->
      <img style="width: 190px; height: 345px;" src="@/assets/news-network/valiant-paradox-1.jpg" class="rounded">
      &nbsp;
      <!-- pic3572C7FBF6FC88B256365FE3A19C2226.jpg -->
      <img style="width: 190px; height: 345px;" src="@/assets/news-network/valiant-paradox-2.jpg" class="rounded">
    </center>
    <p>
      Finally, Duke Exeter has created The Powerjouster for Knights and The Samuraizor for Samurais!
    </p>
    <center>
      <!-- pic5749141CED2CFF5A1B85079396C92170.jpg -->
      <img style="width: 190px; height: 345px;" src="@/assets/news-network/valiant-sentinel-1.jpg" class="rounded">
      &nbsp;
      <!-- pic1CF51EA9E2CDF964224AB4030A7DF3C0.jpg -->
      <img style="width: 190px; height: 345px;" src="@/assets/news-network/valiant-sentinel-2.jpg" class="rounded">
    </center>
    <p>
      While you are saving up for your Valiant Weapons, Achilles Plutarch has other great gear to buy! His new shop, located in the Sentinel shopping area, has a slew of new gear to buy!  He’s got Heavy Staffs, Scarlet Scythes, and more!  You’ll be able to put enemies to sleep, while protecting yourself from 10 points of damage or even heal your teammates for 10 Life Points!
    </p>
    <center>
      <!-- pic4967CC1E1201FB340287B24814701D5B.jpg -->
      <img style="width: 190px; height: 345px;" src="@/assets/news-network/valiant-generic-1.jpg" class="rounded">
      &nbsp;
      <!-- pic0C28B96B2973BB79ECC5E2E1F00D04FC.jpg -->
      <img style="width: 190px; height: 345px;" src="@/assets/news-network/valiant-generic-2.jpg" class="rounded">
    </center>
    <p>
      Nexus Tower is sure to become your new favorite shopping destination.  With over a dozen new Vendors, there is so much to see and buy!
      What will you buy first?
    </p>
    <center>
      <!-- pic751358D40195ABF882751CF69FD1C335.jpg -->
      <img src="@/assets/news-network/valiant-weapons-4.jpg" class="rounded">
    </center>
    <p>
      Discuss this article and your favorite Valiant Weapon on the
      <router-link to="/messageboards/3767008">message boards</router-link>!
    </p>
  </div>
</template>
